import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import { margin, breakpoints } from 'src/modules/styles';
import { PageContainer, ALink } from 'src/modules/layout';
import { SEO } from 'src/modules/seo';
import { Subtitle, Text, Paragraph } from 'src/modules/text';

const S = {
  PageContainer: styled(PageContainer)`
    justify-content: flex-start;
  `,
  TextWrapper: styled.div`
    ${margin.bottom.s}
    width: 100%;
  `,
  Label: styled(Text)`
    display: inline-block;
    width: 45px;
    text-align: center;

    ${breakpoints.xl`
     width: 55px;
    `}
  `,
  Subtitle: styled(Subtitle)`
    ${margin.top.l}
  `,
  SocialWrapper: styled.div`
    display: flex;
    flex-direction: column;
    ${margin.top.m}
    width: 100%;
  `,
  TextUppercase: styled(Text)`
    text-transform: uppercase;
  `,
};

export const ContactPageTemplate = ({
  lang,
  metaTitle,
  metaDescription,
  workingHoursTitle,
  workingHours,
  contactTitle,
  address,
  googleMapsUrl,
  phones,
  fax,
  email,
  socials,
  transitionStatus,
}) => {
  return (
    <S.PageContainer transitionStatus={transitionStatus}>
      <SEO title={metaTitle} description={metaDescription} />
      <Subtitle>{workingHoursTitle}</Subtitle>
      <Paragraph>{workingHours}</Paragraph>
      <S.Subtitle>{contactTitle}</S.Subtitle>
      <S.TextWrapper>
        <S.Label>{lang === 'en' ? 'A' : 'Δ'}</S.Label>
        <Text>: </Text>
        <ALink href={googleMapsUrl} target="_blank">
          {address}
        </ALink>
      </S.TextWrapper>
      <S.TextWrapper>
        <S.Label>{lang === 'en' ? 'P' : 'Τ'}</S.Label>
        <Text>: </Text>
        <Text>{phones.map(({ number }) => number).join(' / ')}</Text>
      </S.TextWrapper>
      {!!fax && (
        <S.TextWrapper>
          <S.Label>{lang === 'en' ? 'F' : 'Φ'}</S.Label>
          <Text>: </Text>
          <Text>{fax}</Text>
        </S.TextWrapper>
      )}
      <S.TextWrapper>
        <S.Label>{lang === 'en' ? 'E' : 'Ε'}</S.Label>
        <Text>: </Text>
        <ALink href={`mailto:${email}`}>{email}</ALink>
      </S.TextWrapper>
      {socials.map(({ title, name, url }) => (
        <S.SocialWrapper>
          <S.TextUppercase>{title}</S.TextUppercase>
          <ALink href={url} target="_blank">
            {name}
          </ALink>
        </S.SocialWrapper>
      ))}
    </S.PageContainer>
  );
};

const phonesPropTypes = PropTypes.arrayOf({
  number: PropTypes.string.isRequired,
}).isRequired;

const socialsPropTypes = PropTypes.arrayOf({
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}).isRequired;

ContactPageTemplate.propTypes = {
  metaTitle: PropTypes.string.isRequired,
  metaDescription: PropTypes.string.isRequired,
  workingHoursTitle: PropTypes.string.isRequired,
  workingHours: PropTypes.string.isRequired,
  contactTitle: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  googleMapsUrl: PropTypes.string.isRequired,
  phones: phonesPropTypes,
  fax: PropTypes.string,
  email: PropTypes.string.isRequired,
  socials: socialsPropTypes,
  transitionStatus: PropTypes.string.isRequired,
};

ContactPageTemplate.defaultProps = {
  phones: [],
  fax: null,
  socials: [],
};

const ContactPage = ({ transitionStatus, data }) => {
  const { markdownRemark: post } = data;
  const {
    metaTitle,
    metaDescription,
    workingHoursTitle,
    workingHours,
    contactTitle,
    address,
    googleMapsUrl,
    phones,
    fax,
    email,
    socials,
  } = post.frontmatter;
  const { langKey } = post.fields;

  return (
    <ContactPageTemplate
      lang={langKey}
      metaTitle={metaTitle}
      metaDescription={metaDescription}
      transitionStatus={transitionStatus}
      workingHoursTitle={workingHoursTitle}
      workingHours={workingHours}
      contactTitle={contactTitle}
      address={address}
      googleMapsUrl={googleMapsUrl}
      phones={phones}
      fax={fax}
      email={email}
      socials={socials}
    />
  );
};

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        workingHoursTitle: PropTypes.string.isRequired,
        workingHours: PropTypes.string.isRequired,
        contactTitle: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        googleMapsUrl: PropTypes.string.isRequired,
        phones: phonesPropTypes,
        fax: PropTypes.string,
        email: PropTypes.string.isRequired,
        socials: socialsPropTypes,
      }),
    }),
  }).isRequired,
  transitionStatus: PropTypes.string.isRequired,
};

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        langKey
      }
      frontmatter {
        metaTitle
        metaDescription
        workingHoursTitle
        workingHours
        contactTitle
        address
        googleMapsUrl
        phones {
          number
        }
        fax
        email
        socials {
          title
          name
          url
        }
      }
    }
  }
`;
